const theme = {
  transparent: 'transparent',

  black: '#000',
  white: '#fff',

  gray: {
    100: '#f7fafc',
    200: '#edf2f7',
    300: '#e2e8f0',
    400: '#cbd5e0',
    500: '#a0aec0',
    600: '#718096',
    700: '#4a5568',
    800: '#2d3748',
    900: '#1a202c',
  },
  darkGray: '#4A4A4A',
  blue: '#1A6E99',
  lightblue: '#E8F1F5',
  magenta: '#C30061',
  lightBeige: '#F4F3F0',
  beige: '#E9E7E1',

  layers: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900],
}

export const breakpoints = {
  xsmall: '480px',
  small: '768px', //mobile
  medium: '992px',
  large: '1200px',
}

export const shadows = {
  smallest: '0 0 1px 0 rgba(0, 0, 0, 0.1)',
  small: '0 4px 8px 0 rgba(0, 0, 0, 0.08)',
}

export const borderRadius = {
  small: '10px 10px 0 10px',
  default: '20px 20px 0 20px',
}

export default theme
