import React from 'react'

const SvgSearch = props => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M41.578 39.394L31.566 29.288a14.317 14.317 0 003.14-8.954c0-7.921-6.422-14.343-14.353-14.343S6 12.42 6 20.344c0 7.922 6.422 14.343 14.353 14.343 3.431 0 6.572-1.2 9.047-3.206l9.947 10.04c.3.32.712.479 1.115.479.385 0 .77-.14 1.06-.422.619-.59.637-1.566.056-2.184zm-21.225-7.79c-3.01 0-5.84-1.173-7.969-3.3a11.192 11.192 0 01-3.3-7.96c0-3.01 1.172-5.84 3.3-7.96a11.197 11.197 0 017.97-3.3c3.009 0 5.84 1.172 7.968 3.3a11.192 11.192 0 013.3 7.96c0 3.01-1.172 5.84-3.3 7.96a11.198 11.198 0 01-7.969 3.3z"
      fill="currentColor"
    />
  </svg>
)

export default SvgSearch
