import React from 'react'
import styled from 'styled-components'
import { respondTo } from '../utils'

const BaseWrapper = styled.div`
  padding: 16px;
  position: relative;
  display: block;
  background: ${props => (props.color ? props.theme[props.color] : '#fff')};
  z-index: ${({ theme, layer }) => theme.layers[layer]};

  ${respondTo.small`
    padding: ${props => (props.noPadding ? '1.5rem 2rem' : '5rem 2rem')};
  `}
`

const FlexWrapper = styled(BaseWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justify || 'flex-start'};
  flex-wrap: wrap;

  ${respondTo.small`
    flex-direction: row;
  `}

  & > * {
    width: 100%;

    ${respondTo.small`
      width: calc(50% - ${props => props.gap || '1.5rem'});
    `}

    ${respondTo.medium`
      width: calc(${props => `${100 / props.flex}%`} - ${props =>
      props.gap || '1.5rem'});
    `}

    &:nth-of-type(${props => props.flex}n+${props => props.flex}) {
      margin-right: 0;
    }
  }
`

const Subtitle = styled.h3`
  width: calc(100% - 4rem);
  color: ${({ theme }) => theme.magenta};
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  margin-bottom: 0.5rem;
`

const PageSection = ({
  color,
  flex,
  justify,
  gap,
  sectionHeading,
  layer,
  children,
  noPadding,
  noSubtitle,
}) => {
  return flex ? (
    <FlexWrapper
      color={color}
      layer={layer}
      noPadding={noPadding}
      flex={flex}
      justify={justify}
      gap={gap}
    >
      <Subtitle>{sectionHeading}</Subtitle>
      {children}
    </FlexWrapper>
  ) : (
    <BaseWrapper color={color} layer={layer} noPadding={noPadding}>
      <Subtitle hide={noSubtitle}>{sectionHeading}</Subtitle>
      {children}
    </BaseWrapper>
  )
}

export default PageSection
