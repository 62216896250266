import UrlMap from '../assets/js/urlMap.json'
/**
 * This functions looks up the id of a page in the UrlMap file. If it finds a match it returns the url for that page.
 * If no mathc is found, or if the id is undefined, it will link to user to the 404 page.
 *
 * @param {String | Symbol} id unique contentful_id as provided by Contentful.
 */
const linkToId = id => {
  if (!id || !UrlMap[id]) {
    return '/404/'
  }
  return UrlMap[id]
}

export default linkToId
