import styled from 'styled-components'

const OverviewSection = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  & + & {
    margin-top: 3rem;
  }

  ::after {
    content: "";
    width: calc((100% / 3) - 1rem);
  }
`

export default OverviewSection;