import React from 'react'
import styled from 'styled-components'
import { respondTo } from '../utils';
import { Link } from 'gatsby'

const LinkButton = props => <Link
  to={props.url}
  className={props.className}
  style={props.style}
>{props.children}</Link>

const ButtonButton = props => <button
  onClick={props.clickFunction}
  className={props.className}
  style={props.style}
>{props.children}</button>


const CustomButton = (props) => {
  return props.url
  ? <LinkButton {...props}>{props.children}</LinkButton>
  : <ButtonButton {...props}>{props.children}</ButtonButton>
}

const DZButton = styled(CustomButton)`
  height: 50px;
  width: 50px;
  text-align: center;
  z-index: 999;

  color: ${({theme}) => theme.blue};
  background-color: ${({theme}) => theme.white};
  border-radius: 20px 20px 0px 20px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);

  font-size: 20px;
  font-weight: bold;
  line-height: 3rem;

  :focus{
    outline: 0;
  }

  transition: transform 300ms, box-shadow 300ms;
  transform: translateY(0);
  :hover {
    box-shadow: 3px 6px 15px rgba(0,0,0,0.3);
    transform: translateY(-0.1em);
  }

  ${respondTo.small`
    height: 75px;
    width: 75px;
    line-height: 4.5rem;
    font-size: 30px;
  `}
`
export default DZButton