import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { transparentize, lighten, darken } from 'polished'
import { BrowserView, MobileView } from 'react-device-detect'

const _AZBar = styled.ul`
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: ${({ theme }) => theme.layers[1] + 1};
  display: flex;

  overflow-x: auto;

  background: ${({ theme }) => theme.lightBeige};
  border-bottom: 3px solid rgba(0, 0, 0, 0.125);
  padding: 0 10px;

  li {
    cursor: pointer;
    flex: 1 0 auto;
    font: normal bold 1.125rem/75px neo-sans, sans-serif;
    height: 75px;
    width: 50px;

    & + & {
      margin-left: 10px;
    }

    color: ${({ theme }) => theme.blue};
    text-align: center;
    text-decoration: none;

    &:hover {
      background: ${({ theme }) =>
        transparentize(0.8, lighten(0.5, theme.blue))};
      color: ${({ theme }) => theme.magenta};
    }

    &:focus {
      outline: 0;
    }
  }
`

const _MobileAZBar = styled(_AZBar)`
  position: fixed;
  top: calc(var(--vhMobile) - 75px);
  border-top: 2px solid rgba(0, 0, 0, 0.125);
  border-bottom: none;

  &::before,
  &::after {
    content: '';
    position: fixed;
    top: calc(var(--vhMobile) - 75px);
    height: 75px;
    z-index: 1;
    width: 32px;
    pointer-events: none;
    transition: opacity 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &::before {
    left: 0;
    background: linear-gradient(
      to right,
      ${({ theme }) => transparentize(0.2, darken(0.2, theme.beige))},
      ${({ theme }) => transparentize(1, theme.beige)}
    );
    opacity: ${props => (props.scrollIndicators[0] ? 1 : 0)};
  }
  &::after {
    right: 0;
    background: linear-gradient(
      to left,
      ${({ theme }) => transparentize(0.2, darken(0.2, theme.beige))},
      ${({ theme }) => transparentize(1, theme.beige)}
    );
    opacity: ${props => (props.scrollIndicators[1] ? 1 : 0)};
  }
  > li {
    width: 75px;
  }
`

const AZBar = ({ children }) => {
  const [scrollIndicators, setScrollIndicators] = useState([false, true])

  let isTicking = false
  const scrollRef = useRef(null)
  function update() {
    isTicking = false
    if (!scrollRef.current) return null
    const leftIndicator = scrollRef.current.scrollLeft > 0
    const rightIndicator =
      scrollRef.current.scrollWidth -
        scrollRef.current.offsetWidth -
        scrollRef.current.scrollLeft >
      0
    setScrollIndicators([leftIndicator, rightIndicator])
  }

  function onScroll() {
    requestTick()
  }

  function requestTick() {
    if (!isTicking) {
      requestAnimationFrame(update)
    }
    isTicking = true
  }
  return (
    <>
      <MobileView renderWithFragment>
        <_MobileAZBar
          ref={scrollRef}
          onScroll={onScroll}
          scrollIndicators={scrollIndicators}
        >
          {children}
        </_MobileAZBar>
      </MobileView>
      <BrowserView renderWithFragment>
        <_AZBar ref={scrollRef}>{children}</_AZBar>
      </BrowserView>
    </>
  )
}

export default AZBar
