import styled from 'styled-components'

const FirstLetter = styled.h2`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  height: 3rem;
  width: 3rem;
  margin-bottom: 1rem;
  margin-right: 100%;

  color: ${({theme}) => theme.white};
  background-color: ${({theme}) => theme.magenta};
  border-radius: 10px 10px 0 10px;

  font: normal bold 1.5rem/1.5 neo-sans, sans-serif;
`

export default FirstLetter;