import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
  background-color: white;
`

export default Wrapper
