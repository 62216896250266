const mapDetailTables = (data, mode = 'default') => {
  let {
    aanvraagcode,
    aanvraagformulier,
    afdeling,
    afnamecondities,
    afnameVolume,
    alternatiefMateriaal,
    analyseFrequentie,
    benodigdeInformatie,
    bewaarcondities,
    bewaarperiode,
    bronvermelding,
    citoAanvraagbaar,
    contactpersoon,
    doorbelgrenzen,
    doorlooptijd,
    eenheid,
    indicatie,
    interpretatie,
    lisCode,
    materiaal,
    methode,
    minimaleAfnamehoeveelheid,
    opmerkingen,
    referentiewaarden,
    synoniemen,
    tarief,
    testeigenschappen,
    titel,
    verzendcondities,
    verzendformulier,
  } = data

  if (mode === 'preview') {
    interpretatie = {
      childMarkdownRemark: {
        html: interpretatie,
      },
    }
    referentiewaarden = {
      childMarkdownRemark: {
        html: referentiewaarden,
      },
    }
    opmerkingen = {
      childMarkdownRemark: {
        html: opmerkingen,
      },
    }
    bronvermelding = {
      childMarkdownRemark: {
        html: bronvermelding,
      },
    }
    testeigenschappen = {
      childMarkdownRemark: {
        html: testeigenschappen,
      },
    }
  }

  return {
    algemeen: {
      sectionHeading: 'Aanvraaginformatie',
      firstColumn: [
        { label: 'Omschrijving', value: titel },
        { label: 'Synoniemen', value: synoniemen },
        { label: 'Aanvraagcode', value: aanvraagcode },
        {
          label: 'Uitvoerend Lab',
          value: afdeling ? afdeling.afdeling : '',
        },
        {
          label: 'Aanvraagformulier',
          value: aanvraagformulier && aanvraagformulier.titel,
          subValues: aanvraagformulier && [
            { Link: aanvraagformulier.weblink },
            { 'Verzending Naar': aanvraagformulier.verzendingNaar },
            { Contactgegevens: aanvraagformulier.contactgegevens },
          ],
        },
      ],
      secondColumn: [
        { label: 'Benodigde Informatie', value: benodigdeInformatie },
        { label: 'CITO Aanvraagbaar', value: citoAanvraagbaar ? 'Ja' : 'Nee' },
        {
          label: 'Contactpersoon',
          value: contactpersoon ? contactpersoon.contactpersoon : '',
          subValues: contactpersoon && [
            { Telefoonnummer: contactpersoon.telefoonnummer },
            { Mailadres: contactpersoon.email },
          ],
        },
        {
          label: 'Opmerkingen',
          value: opmerkingen,
        },
      ],
    },
    verzenden: {
      sectionHeading: 'Materiaal afname/opslag',
      firstColumn: [
        {
          label: 'Materiaal',
          value: materiaal && materiaal.titel,
          subValues: materiaal && [
            { Code: materiaal.code },
            { 'Kleur Dop': materiaal.kleurDop },
            { Afbeelding: materiaal.afbeelding },
          ],
        },
        {
          label: 'Alternatief Materiaal',
          value: alternatiefMateriaal && alternatiefMateriaal.titel,
          subValues: alternatiefMateriaal && [
            { Code: alternatiefMateriaal.code },
            { 'Kleur Dop': alternatiefMateriaal.kleurDop },
            { Afbeelding: alternatiefMateriaal.afbeelding },
          ],
        },
        { label: 'Afname Volume', value: afnameVolume },
        { label: 'Minimaal nodig', value: minimaleAfnamehoeveelheid },
        { label: 'Afnameconditie', value: afnamecondities },
      ],
      secondColumn: [
        { label: 'Bewaarcondities', value: bewaarcondities },
        { label: 'Bewaarperiode', value: bewaarperiode },
        {
          label: 'Verzendformulier',
          value: verzendformulier && verzendformulier.titel,
          subValues: verzendformulier && [
            { Link: verzendformulier.weblink },
            { 'Verzending Naar': verzendformulier.verzendingNaar },
            { Contactgegevens: verzendformulier.contactgegevens },
          ],
        },
        { label: 'Verzendcondities', value: verzendcondities },
      ],
    },
    behouden: {
      sectionHeading: 'Testeigenschappen',
      firstColumn: [
        { label: 'Methode', value: methode && methode.methode },
        {
          label: 'Analyse Frequentie',
          value: analyseFrequentie ? analyseFrequentie.frequentie : '',
        },
        { label: 'Doorlooptijd', value: doorlooptijd },
      ],
      secondColumn: [
        { label: 'CTG-Klasse', value: tarief && tarief.klasse },
        {
          label: 'Tarief',
          value:
            tarief &&
            `€${tarief.tarief.toLocaleString('nl-NL', { currency: 'EUR' })}`,
        },
        { label: 'LIS Code', value: lisCode },
      ],
    },
    interpretatie: {
      sectionHeading: 'Interpretatie',
      firstColumn: [
        { label: 'Indicatie', value: indicatie },
        { label: 'Eenheid', value: eenheid },
        { label: 'Referentiewaarden', value: referentiewaarden },
        { label: 'Interpretatie', value: interpretatie },
        { label: 'Testeigenschappen', value: testeigenschappen },
        { label: 'Doorbelgrenzen', value: doorbelgrenzen },
        { label: 'Bronvermelding', value: bronvermelding },
      ],
    },
  }
}

export default mapDetailTables
