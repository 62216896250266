import React, { useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import theme from '../theme'
import { Helmet } from 'react-helmet'
import { Logo } from '../components/icons'
import { respondTo } from '../utils'
import { debounce } from 'lodash-es'
import GatsbyLink from 'gatsby-link'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  height: auto;
  padding: 1rem 2rem;
  top: 0;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  background: white;

  ${respondTo.small`
    height: 100px;
  `};
`
const PageTitle = styled.h1`
  color: ${({ theme }) => theme.blue};
  font-size: 1.5rem;
  font-weight: bold;

  ${respondTo.small`
    font-size: 2rem;
  `}
`
const Link = styled(GatsbyLink)`
  height: auto;
  flex: 0 1 150px;
`

const Layout = ({ pageTitle, children }) => {
  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener(
      'resize',
      debounce(handleWindowSizeChange, 100, { leading: true, trailing: true })
    )
    return () => {
      window.removeEventListener(
        'resize',
        debounce(handleWindowSizeChange, 100, { leading: true, trailing: true })
      )
    }
  }, [])

  function handleWindowSizeChange() {
    let innerHeight = window.innerHeight
    document.documentElement.style.setProperty('--vhMobile', `${innerHeight}px`)
  }

  return (
    <>
      <Helmet>
        <title>Labgids | Deventer Ziekenhuis</title>
        <html lang="nl"></html>
        <meta
          name="description"
          content="Welkom bij de labgids van het Deventer Ziekenhuis."
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <>
          <Header>
            <Link to="/" aria-label="Back to home">
              <Logo />
            </Link>
            {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
          </Header>
          <main>{children}</main>
        </>
      </ThemeProvider>
    </>
  )
}

export default Layout
