import styled from 'styled-components'
import { Link } from 'gatsby'
import { respondTo } from '../utils'

const OverviewSection = styled(Link)`
  background: ${({theme}) => theme.lightBeige };
  padding: 1.5rem;
  color: ${({theme}) => theme.blue };
  border-radius: 20px 20px 0 20px;
  font-weight: bold;
  display: inline;
  flex: 0 1 100%;

  margin-bottom: 1rem;

  ${respondTo.small`
    flex: 0 1 calc((100% / 2) - 1rem);
  `}

  ${respondTo.medium`
    flex: 0 1 calc((100% / 3) - 1rem);
  `}
`

export default OverviewSection;