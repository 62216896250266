const searchToMap = (search = '') => {
  if (search.length === 0) return {}
  const searchesArr = search.substring(1).split('&')
  return searchesArr.reduce((acc, cv) => {
    const query = cv.split('=')
    if (query.length === 2) {
      acc = {
        ...acc,
        [query[0]]: query[1],
      }
    }
    return acc
  }, {})
}

export default searchToMap
