import { css } from 'styled-components'
import { breakpoints, shadows, borderRadius } from '../theme'

const respondTo = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})
/**
 * @param {string} key small | default
 * @returns border radius with bottom-right set to 0. other corner values are based on the value in theme
 */
const useBorderRadius = (key = 'default') => borderRadius[key]
/**
 * @param {string} key smallest | small
 * @returns one of the box-shadows defined in theme
 */
const useShadow = (key = 'small') => shadows[key]

export { respondTo, useBorderRadius, useShadow }
