import styled from 'styled-components'
import { respondTo } from '../utils'

const CallToAction = styled.div`
  color: ${({ theme }) => theme.darkGray};
  font-size: 1.2rem;
  margin-bottom: 2rem;
  width: 100%;
  align-self: center;
  background-color: ${({ theme }) => theme.lightBeige};
  border-radius: 1rem;

  p {
    padding: 1rem 0;
    line-height: 1.5;
    /* Font */
  }

  ${respondTo.small`
    padding: 2rem;
    margin-bottom: 0;
    margin-right: 2rem;
    flex-direction: row;

    p {
      line-height: normal;
      padding: 0;
      margin-bottom: 2rem;

    }
  `}

  ${respondTo.large`
    margin-right: 0;
    max-width: calc(100% - 500px);

    justify-content: space-around;
  `}
`

export default CallToAction
